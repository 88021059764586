$(document).ready(function () {
  //BLOQUEAR MENU
  // $('body').on('click', '.menu-zabalera-blokeatu', function () {
  //   $(".barramenu").toggleClass("menu-zabalduta");
  //   $(".body-content").toggleClass("menu-zabalduta");
  //   $(".barratop").toggleClass("menu-zabalduta");
  // });

  //DESPLEGAR CONFIGURACIÓN
  $('body').on('click', '.desplegar-menuconf', function () {
    $(".conf-menu").toggleClass("agertu");
    $(".titulo-menu-configuracion").toggleClass("agertu");
  });

  //PLEGAR - DESPLEGAR PANELES

  /* $(".plegarpanel").click(function () {
       console.log ("click");
       $(this).parent(".nuevo-panel").toggleClass("plegado");
   });

   $(".nuevo-panel").click(function () {
       console.log ("click");
   });*/

  $('body').on('click', '.plegarpanel', function () {
    $(this).parent().parent(".card").toggleClass("plegado");
    console.log("click");
  });

  //ITXURA ALDATU ILUNA/ARGIA

  /*  $(".itxura-aldaketa").click(function () {
        $("body").toggleClass("iluna");
    });*/


  // $('body').on('click', '.itxura-aldaketa-iluna', function () {
  //   $("body").addClass("iluna");
  //   $(".itxura-aldaketa-iluna").addClass("aukeratuta");
  //   $(".itxura-aldaketa-argia").removeClass("aukeratuta");
  // });


  // $('body').on('click', '.itxura-aldaketa-argia', function () {
  //   $("body").removeClass("iluna");
  //   $(".itxura-aldaketa-argia").addClass("aukeratuta");
  //   $(".itxura-aldaketa-iluna").removeClass("aukeratuta");
  // });
});

